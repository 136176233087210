import Home from '../pages/home'
import HouseIntelligence from '../pages/HouseIntelligence'
import ProductCenter from '../pages/productCenter'
import ProductCenterChild from '../pages/productCenterChild'
import Solution from '../pages/solution'
import Aboutus from '../pages/aboutUs'

// const MenuRouter = () => {
//     return (
//         // <BrowserRouter>
//         <Routes>
//             <Route exact path="/" element={<Home />} />
//             <Route exact path="/home" element={<Home />} />
//             <Route path="/about" element={<About />} />
//             {/* <Route path="/habdc" component={Habdc} /> */}
//         </Routes>
//         // </BrowserRouter>
//     );
// };

// export default MenuRouter();

const routers = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/home',
        name: '首页',
        element: <Home />,
    },
    {
        path: '/House',
        name: '全屋智能',
        element: <HouseIntelligence />,
    },


    {
        path: '/productCenter/people',
        element: <ProductCenter />,
    },
    {
        path: '/productCenter/ladder',
        element: <ProductCenter />,
    },
    {
        path: '/productCenter/all',
        name: '产品中心',
        element: <ProductCenter />,
    },

    {
        path: "/productCenter/:id",
        element: <ProductCenterChild />,
    },
    {
        path: '/solution',
        name: '解决方案',
        element: <Solution />,
    },
    {
        path: '/aboutus/CompanyProfile',
        name: '关于我们',
        element: <Aboutus />,
    },
    {
        path: '/aboutus/Culture',
        element: <Aboutus />,
    },
    {
        path: '/aboutus/Contact',
        element: <Aboutus />,
    },
];

export default routers;

