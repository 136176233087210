import React, { useState } from 'react';
import { Layout, theme } from 'antd';
import { useRoutes, useLocation, NavLink, useNavigate } from 'react-router-dom';
import logo from "./static/logo.png"
import Router from './routes'
import './App.css'
import 'swiper/swiper-bundle.min.css';
import wx from '../src/static/wx.png'
import email from '../src/static/email.png'
import phone from '../src/static/phone.png'
import jgwab from '../src/static/jgwab.png'
const { Header, Content, Footer } = Layout;
// 菜单项数据
const menuData = [
  {
    label: '首页',
    key: '/home',
  },
  {
    label: '全屋智能',
    key: '/House',
  },
  {
    label: '产品中心',
    id: "productCenter",
    key: "/productCenter/all",
    children: [
      {
        label: "智慧人行",
        key: "/productCenter/people?id=people",
        id: "productCenter",
      },
      {
        label: "智慧梯行",
        key: "/productCenter/ladder?id=ladder",
        id: "productCenter",
      },
      // {
      //   label: "智能家居",
      //   key: "/productCenter/all",
      //   id: "productCenter",
      // },
    ]
  },
  {
    label: '解决方案',
    key: '/solution',
  },
  {
    label: '关于我们',
    id: "aboutus",
    key: "/aboutus/CompanyProfile?id=CompanyProfile",
    children: [
      {
        label: "公司简介",
        key: "/aboutus/CompanyProfile?id=CompanyProfile",
        id: "aboutus",
      },
      {
        label: "公司文化",
        key: "/aboutus/Culture?id=Culture",
        id: "aboutus",
      },
      {
        label: "联系我们",
        key: "/aboutus/Contact?id=Contact",
        id: "aboutus",
      },
    ]
  },
];

//页脚
const footerItem = [
  {
    title: '推荐产品',
    links: [
      { label: '10寸智能中控屏', url: '/productCenter/10inchIntelligent' },
      { label: '7寸智能中控', url: '/productCenter/7inchIntelligent' },
      { label: '五方对讲机', url: '/productCenter/fiveExtension' },
    ]
  },
  {
    title: '关于我们',
    links: [
      { label: '公司简介', url: '/aboutus/CompanyProfile?id=CompanyProfile' },
      { label: '加入我们', url: '/aboutus/Contact?id=Contact' },
    ]
  },
  {
    title: '联系我们',
    links: [
      { label: '0756-3613268', icon: phone },
      { label: 'dpower@163.com', icon: email },
      { label: 'dpower', icon: wx },
    ]
  },
]
const App = (props) => {
  const renderedRoutes = useRoutes(Router);
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [OnClickSubMenu, setOnClickSubMenu] = useState(null);


  const handleMouseEnter = (item) => {
    if (item.children) {
      setActiveSubMenu(item);
    }
  };
  const handleMouseLeave = (item) => {
    if (activeSubMenu === item) {
      setActiveSubMenu(item);
    } else {
      setActiveSubMenu(null);
    }
  };

  return (
    <Layout className="layout">
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: "#fff",
          padding: "0 48px",
          height: "120px",
          // 视口固定
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 100,
        }}
      >
        <div className="demo-logo" style={{ lineHeight: "50%" }} >
          <img src={logo} alt="logo" />
        </div>
        <nav style={{ display: 'flex', fontSize: "24px" }}>
          {menuData.map((item) => (
            <div
              key={item.key}
              onMouseEnter={() => handleMouseEnter(item)}
              onMouseLeave={handleMouseLeave}
              className='main-menu'
            >

              <div
                className='main-menu-item'
                onClick={() => {
                  if (item.key) {
                    navigate(item.key)
                    item.id ? setOnClickSubMenu(item.id) : setOnClickSubMenu(null)
                  }
                }}
                style={{
                  color: pathname === item.key || OnClickSubMenu === item.id ? "#020052" : " #757575"
                }}>
                {item.label}
              </div>

              {activeSubMenu === item && (
                <div className='menu-submenu'
                  onMouseLeave={() => handleMouseLeave(item)}
                >
                  {activeSubMenu.children.map((child) => (
                    <div className='menu-submenu-item'>
                      <NavLink style={{ color: "#fff" }} onClick={() => { setOnClickSubMenu(child.id) }} key={child.key} to={child.key} >{child.label}</NavLink>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

        </nav>
      </Header>
      <div style={{ height: "120px" }}></div>
      <Content
        style={{
        }}
      >
        <div
          className="site-layout-content"
          style={{
            background: colorBgContainer,
          }}
        >
          {renderedRoutes}
        </div>
      </Content>

      <Footer
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          borderBottom: "1px solid #757575",
          maxWidth: "1520px",
          margin: "0 auto",
        }}>
          {
            footerItem.map((item, index) => {
              return <div key={index} style={{
                display: "flex",
                flexDirection: "column",
                lineHeight: "20px",
                whiteSpace: "nowrap",
                justifyContent: 'flex-start'
              }}>
                <p style={{ fontSize: "20px", color: "#020052", fontWeight: "bold", float: "left", textAlign: "left" }}>{item.title}</p>
                {item.links.map((link, linkIndex) => (
                  <div style={{ fontSize: "15px", marginBottom: "15px", color: "#000", height: "24px", display: "flex", alignItems: "center" }} key={linkIndex} >
                    {link.icon ? <img src={link.icon} alt='' style={{ marginRight: "10px", width: "20px" }} /> : null}
                    <div style={{ cursor: link.url ? "pointer" : "" }}
                      onClick={() => {
                        if (link.url) {
                          navigate(link.url)
                        }
                      }}>{link.label}</div>
                  </div>
                ))}
              </div>
            })
          }
        </div>
        <div style={{ color: "#757575", margin: "22px 0", whiteSpace: "nowrap", textAlign: "center", }}>Copyright©2022- 2023 珠海数字动力科技股份有限公司 版权所有 网站地图  隐私政策 </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href='https://beian.miit.gov.cn/#/Integrated/index' target="_blank" rel="noopener noreferrer">粤ICP备14075625号 </a>
          <div style={{ display: "flex" }}>
            <img alt='' src={jgwab} style={{ width: "20px", height: "20px", marginRight: "5px", marginLeft: "20px" }} />
            <a href='https://beian.mps.gov.cn/#/query/webSearch?code=44049102496564' target="_blank" rel="noopener noreferrer">粤公网安备44049102496564号</a>
          </div>
        </div>
      </Footer>
    </Layout>
  );
};
export default App;
