import React, { useEffect } from 'react'
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
// import 'swiper/swiper-bundle.min.css';
import { Swiper, Pagination, Autoplay, Navigation } from 'swiper';
import './index.css'

import home from '../../static/home/home1.png'
import home2 from '../../static/home/home2.png'
import home3 from '../../static/home/home3.png'
import m1 from '../../static/home/m1.png'
import m7 from '../../static/home/7.png'
import m4 from '../../static/home/4.png'
import m10 from '../../static/home/10.png'
import door from '../../static/home/door.png'
import Business from '../../static/home/Business1.png'
import spell from '../../static/home/sleep.png'
import athome from '../../static/home/atHome.png'
import film from '../../static/home/film.png'
import dining from '../../static/home/dining.png'
const Home = () => {
    const navigate = useNavigate()
    const items = [
        {
            img: m4,
            p1: '4寸智能中控',
            p2: '智能控制、场景控制、语音控制'
        },
        {
            img: m10,
            p1: '10寸智能中控',
            p2: '智能控制、场景控制、语音控制'
        },
        {
            img: door,
            p1: '门口机',
            p2: '访客呼叫、呼叫物管、密码开门、刷卡开门、人脸开门、二维码开门、呼梯'
        },
    ]
    Swiper.use([Pagination, Autoplay, Navigation]);
    useEffect(() => {
        window.scrollTo(0, 0);
        new Swiper('.container-homeTop', {
            autoplay: true,
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }
        });
        new Swiper('.container-homeCenter', {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            slidesPerView: 2,
            // spaceBetween: 100,
            centeredSlides: true,
            loop: true,
        });
    }, []);

    return (
        <div className='home'>
            <div className="swiper-container container-homeTop">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <a><img src={home} alt=""></img>
                        </a>
                    </div>
                    <div className="swiper-slide">
                        <a>
                            <img src={home2} alt=""></img>
                        </a>
                    </div>
                    <div className="swiper-slide">
                        <a><img src={home3} alt=""></img>
                        </a>
                    </div>
                </div>
                <div className="swiper-pagination"></div>
            </div>

            <div className='scene_text'>
                <p style={{ fontSize: "40px" }}>智能场景</p>
                <p style={{ fontSize: "24px" }}>智能生活，尽在掌握</p>
                <Button className='btn' shape="round" onClick={() => navigate("/House")}>了解更多</Button>
            </div>

            <div className="swiper-container container-homeCenter">
                <div className="swiper-wrapper">
                    <div className="swiper-slide "><a ><img src={athome} alt=""></img></a></div>
                    <div className="swiper-slide"><a><img src={spell} alt="" ></img></a></div>
                    <div className="swiper-slide"><a><img src={film} alt=""></img></a></div>
                    <div className="swiper-slide"><a ><img src={dining} alt=""></img></a></div>
                </div>
                <div className="swiper-button-next swiper-button-white"></div>
                <div className="swiper-button-prev swiper-button-white"></div>
            </div>

            <div className='scene_text'>
                <p style={{ fontSize: "40px" }}>产品中心</p>
                <p style={{ fontSize: "24px" }}>生活更智能，更简单</p>
                <Button className='btn' shape="round" onClick={() => navigate("/productCenter/all")}>了解更多</Button>
            </div>

            <div className='home-marginBox'>


                <div className='Product_Center_Topimg'>
                    <div className='ProductLeft'>
                        <img src={m1} alt="" />
                        <div className='text'>
                            <p style={{ fontSize: "32px" }}>10寸双屏智能中控</p>
                            <p style={{ fontSize: "24px" }}>访客呼叫、呼叫物管、远程开门、家庭安防、智能控制、场景控制、语音控制</p>
                        </div>
                    </div>
                    <div className='ProductRight'>
                        <div className='ProductRight-img'>
                            <img src={m7} alt="" />
                        </div>
                        <div className='text'>
                            <p className='textP' style={{ fontSize: "32px" }}>7寸智能中控</p>
                            <p style={{ fontSize: "24px" }}>访客呼叫、呼叫物管、远程开门、家庭安防、智能控制、场景控制、语音控制</p>
                        </div>
                    </div>
                </div>

                <div className='Product_Center_Topimg'>
                    {
                        items.map((item, index) => {
                            return <div className='ProductRight' key={index}>
                                <div className='ProductRight-img'>
                                    <img src={item.img} alt="" />
                                </div>
                                <div className='text'>
                                    <p className='textP' style={{ fontSize: "32px" }}>{item.p1}</p>
                                    <p style={{ fontSize: "24px" }}>{item.p2}</p>
                                </div>
                            </div>
                        })
                    }
                </div>

                <div className='scene_text'>
                    <p style={{ fontSize: "40px" }}>商务案例</p>
                    <p style={{ fontSize: "24px" }}>与客户的成功故事，我们的商业合作见证着创新</p>
                    <Button className='btn' shape="round" onClick={() => navigate("/solution")}>了解更多</Button>
                </div>

                <div className='Business_Case'>
                    <div className='Business1'>
                        <img src={Business} alt="" />
                    </div>

                    <div className='BusinesItem'>

                        <div className='BusinesItem1'>
                            <div className='BusinesItemText'>
                                <div>电 梯 五 方 系 统</div>
                                <Button className='btn' shape="round" onClick={() => navigate("/solution")}>了解更多</Button>
                            </div>
                        </div>

                        <div className='BusinesItem2'>
                            <div className='BusinesItemText'>
                                <div>云 人 行</div>
                                <Button className='btn' shape="round" onClick={() => navigate("/solution")}>了解更多</Button>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    )
}
export default Home;
