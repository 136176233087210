import React, { useEffect } from 'react'
import { Swiper, Pagination, Autoplay } from 'swiper';
import './index.css'

import swiper1 from '../../static/House/swiper/swiper1.png'
import swiper2 from '../../static/House/swiper/swiper2.png'
import swiper3 from '../../static/House/swiper/swiper3.png'
import swiper4 from '../../static/House/swiper/swiper4.png'
import swiper5 from '../../static/House/swiper/swiper5.png'
import swiper6 from '../../static/House/swiper/swiper6.png'
import swiper7 from '../../static/House/swiper/swiper7.png'

import icon1 from '../../static/House/icon/icon1.png'
import icon2 from '../../static/House/icon/icon2.png'
import icon3 from '../../static/House/icon/icon3.png'
import icon4 from '../../static/House/icon/icon4.png'
import icon5 from '../../static/House/icon/icon5.png'

import productsImage1 from '../../static/House/productsImage/productsImage1.png'
import productsImage2 from '../../static/House/productsImage/productsImage2.png'
import productsImage3 from '../../static/House/productsImage/productsImage3.png'
import productsImage4 from '../../static/House/productsImage/productsImage4.png'
import productsImage5 from '../../static/House/productsImage/productsImage5.png'

import switch0 from '../../static/House/switch/switch.png'
import switch1 from '../../static/House/switch/switch1.png'
import switch2 from '../../static/House/switch/switch2.png'
import switch3 from '../../static/House/switch/switch3.png'
import switch4 from '../../static/House/switch/switch4.png'
import switch5 from '../../static/House/switch/switch5.png'
import switch6 from '../../static/House/switch/switch6.png'
import switch7 from '../../static/House/switch/switch7.png'

const HouseIntelligence = () => {
    const iconArr = [
        {
            path: icon1,
            name: '可视对讲'
        },
        {
            path: icon2,
            name: '远程开门'
        },
        {
            path: icon3,
            name: '呼叫物业'
        },
        {
            path: icon4,
            name: '智能控制'
        },
        {
            path: icon5,
            name: '语音控制'
        },
    ]
    Swiper.use([Pagination, Autoplay]);
    useEffect(() => {
        window.scrollTo(0, 0);
        new Swiper('.container-houseTop', {
            autoplay: true,
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }
        });
        new Swiper('.container-houseBottom', {
            autoplay: true,
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    let text = '';
                    switch (index) {
                        case 0: text = '用餐模式'; break;
                        case 1: text = '电影模式'; break;
                        case 2: text = '在家模式'; break;
                        case 3: text = '睡眠模式'; break;
                        default: text = '用餐模式'; break;
                    }
                    return '<span class="' + className + '">' + text + '</span>';
                }
            },

        });
    }, []);
    return (
        <div className='houseIntelligence' style={{ background: "#f9f9f9", paddingBottom: "84px" }}>
            <div className="swiper-container container-houseTop">
                <div className="swiper-wrapper">
                    <div className="swiper-slide"><a><img src={swiper1} alt=""></img></a></div>
                    <div className="swiper-slide"><a ><img src={swiper2} alt=""></img></a></div>
                    <div className="swiper-slide"><a><img src={swiper3} alt=""></img></a></div>
                </div>
                <div className="swiper-pagination"></div>
            </div>

            <div className='products-box'>
                <div className='house-scene_text'>
                    <p style={{ fontSize: "40px", whiteSpace: "nowrap" }}>社区联动，畅想智慧居家生活</p>
                    <p style={{ fontSize: "24px", whiteSpace: "nowrap" }} className='scene-text-p'>通过将社区安防管理、物业管理、楼宇对讲、智能家居等服务模块进行多屏融合，打造“智慧社区+全屋智能”一体化联动的生活环境。</p>
                </div>

                <div className='iconBox'>
                    {iconArr.map((item, index) => {
                        return <div className='iconItem'>
                            <img src={item.path} key={index} alt="" />
                            <span>{item.name}</span>
                        </div>
                    })}
                </div>

                <div className='products_img'>
                    <div style={{ marginRight: "32px" }}>
                        <img src={productsImage1} alt="" />
                    </div>

                    <div className='products_img_item'>
                        <div className='products_img_item1'>
                            <img src={productsImage2} alt="" />
                            <img src={productsImage3} alt="" />
                        </div>
                        <div className='products_img_item2'>
                            <img src={productsImage4} alt="" />
                            <img src={productsImage5} alt="" />
                        </div>

                    </div>
                </div>

                <div className='products'>
                    <div className='house-scene_text'>
                        <p style={{ fontSize: "40px", whiteSpace: "nowrap" }}>一款产品，无限可能</p>
                        <p style={{ fontSize: "24px", whiteSpace: "nowrap" }}>轻松掌控，畅享智能便捷</p>
                    </div>
                </div>

                {/* 开关 */}
                <div className='switchBox'>
                    <div className='switchBox_item_left'>
                        <img src={switch0} alt=""></img>
                        <img src={switch7} style={{ marginLeft: "47px" }} alt=""></img>
                    </div>

                    <div>
                        <div className='switchBox_item_right switchBox_item_right1'>
                            <img src={switch1} alt=""></img>
                            <img src={switch2} alt=""></img>
                            <img src={switch3} alt=""></img>
                        </div>
                        <div className='switchBox_item_right'>
                            <img src={switch4} alt=""></img>
                            <img src={switch5} alt=""></img>
                            <img src={switch6} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>
            {/* 智能场景 */}
            <div className='intelligent'>
                <div className='intelligent_text'>
                    <p style={{ fontSize: "40px", whiteSpace: "nowrap", marginBottom: "32px" }}>智能场景</p>
                    <p style={{ fontSize: "24px", whiteSpace: "nowrap" }}>智能生活，尽在掌握</p>
                </div>
            </div>

            <div className="swiper-container container-houseBottom">
                <div className="swiper-wrapper">
                    <div className="swiper-slide"><a><img src={swiper4} alt=""></img></a></div>
                    <div className="swiper-slide"><a ><img src={swiper5} alt=""></img></a></div>
                    <div className="swiper-slide"><a><img src={swiper6} alt=""></img></a></div>
                    <div className="swiper-slide"><a><img src={swiper7} alt=""></img></a></div>
                </div>
                <div className="swiper-pagination"></div>
            </div>
        </div>
    )
}
export default HouseIntelligence;