import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import headerImg from '../../static/productCenter/prdouctChild.png'
import p1 from '../../static/productCenter/productChild/10inch2.png'
import p2 from '../../static/productCenter/productChild/10inch.png'
import p3 from '../../static/productCenter/productChild/7inch.png'
import p4 from '../../static/productCenter/productChild/4inch.png'
import p6 from '../../static/productCenter/productChild/8security.png'
import p7 from '../../static/productCenter/productChild/10inchFace.png'
import p8 from '../../static/productCenter/productChild/8inchFace.png'
import L1 from '../../static/productCenter/productChild/faceLadder.png'
import L2 from '../../static/productCenter/productChild/fiveExtension.png'
import L3 from '../../static/productCenter/productChild/phone.png'
import L4 from '../../static/productCenter/productChild/call.png'
import L5 from '../../static/productCenter/productChild/fivePAD.png'

import b1 from '../../static/productCenter/productChild/bottomImage/10inch2.png'
import b2 from '../../static/productCenter/productChild/bottomImage/10inch.png'
import b3 from '../../static/productCenter/productChild/bottomImage/7inch.png'
import b4 from '../../static/productCenter/productChild/bottomImage/4inch.png'
import b6 from '../../static/productCenter/productChild/bottomImage/8security.png'
import b7 from '../../static/productCenter/productChild/bottomImage/10face.png'
import b8 from '../../static/productCenter/productChild/bottomImage/8face.png'
import b9 from '../../static/productCenter/productChild/bottomImage/faceLadder.png'
import b10 from '../../static/productCenter/productChild/bottomImage/five.png'
import b11 from '../../static/productCenter/productChild/bottomImage/fivePAD.png'
const ProductCenterChild = (props) => {
    const ProductDetails = [
        {
            id: "10inchIntelligentDual",
            name: " 10寸双屏智能中控",
            function: " 访客呼叫、户户通话、呼叫物管、远程开门、家庭安防、记录管理、智能控制、场景控制、语音控制",
            communication: " 以太网0/以太网1/WIFI6/BT5.2",//通讯方式
            size: " 10.1英寸",//屏幕尺寸
            resolution: " 主屏：1280*800，副屏：240*240",//分辨率
            storage: " 8GB eMMC",//存储方式
            workVoltage: " 12~24V",//工作电压
            pic: p1,
            pic2: b1,
        },
        {
            id: "10inchIntelligent",
            name: "10寸智能中控",
            function: "智能控制、场景控制、语音控制",
            communication: " 以太网0/WIFI4/BT5.0",//通讯方式
            size: " 10.1英寸",//屏幕尺寸
            resolution: " 800*1280",//分辨率
            storage: " 8GB eMMC",//存储方式
            workVoltage: " 12~24V DC或220V AC（86盒）",//工作电压
            pic: p2,
            pic2: b2
        },
        {
            id: "7inchIntelligent",
            name: "7寸智能中控",
            function: "访客呼叫、户户通话、呼叫物管、远程开门、家庭安防、记录管理、智能控制、场景控制、语音控制",
            communication: " 以太网//WIFI4/BT5.0",//通讯方式
            size: " 7英寸",//屏幕尺寸
            resolution: " 1024*600",//分辨率
            storage: " 1Gb SPI Nand",//存储方式
            workVoltage: " 12~24V DC 或 220AC(86盒)",//工作电压
            pic: p3,
            pic2: b3
        },
        {
            id: "4inchIntelligent",
            name: "4寸智能中控",
            function: "智能控制、场景控制、语音控制",
            communication: " WIFI4/BT5.0/2.4G",
            size: " 4英寸",
            resolution: " 480*480",
            material: " ABS",//产品材质
            ratedVoltage: " 输入：200V AC 输出：12V/0.5A",//额定电源
            Installation: " 嵌入式86底盒安装",//安装方式
            ProductSize: " 86mm * 86mm*35mm",
            pic: p4,
            pic2: b4
        },
        {
            id: "8inchSecurity",
            name: "8寸保安分机",
            function: "可视对讲、监视开锁、电梯智控、小区服务",
            communication: " 以太网",//通讯方式
            size: " 8英寸",//屏幕尺寸
            resolution: " 800*1280",//分辨率
            storage: " FLASH EMMC KLM8G1WEPD-8031(8Gbyte)",//存储方式
            workVoltage: " 12V 电源输入(范围9V-15V)",//工作电压
            consumption: " 整机最大功耗 <8W",//功耗
            material: " 铝合金（手柄ABS）",//产品材质
            ProductSize: " 220mm*190mm*65mm",//产品尺寸
            pic: p6,
            pic2: b6
        },
        {
            id: "10inchFacial",
            name: "10寸人脸识别门口机",
            function: "访客呼叫、呼叫物管、人脸识别、密码开门、刷卡开门、扫码开门、呼梯",
            communication: " 以太网",//通讯方式
            size: " 10.1英寸",//屏幕尺寸
            resolution: " 1280*800",//分辨率
            material: " 铝合金面板",//产品材质
            workVoltage: " 9-15VDC",//工作电压
            Installation: " 嵌入式/壁挂",//安装方式
            camera: " 彩色：1928*1088 红外：1616*1232",//摄像头
            ProductSize: " 宽190mm*高339mm*深40mm",//产品尺寸
            installSize: " 宽169mm*高320mm*深31mm",//安装尺寸
            pic: p7,
            pic2: b7
        },
        {
            id: "8inchFacial",
            name: "8寸人脸识别门口机",
            function: "访客呼叫、呼叫物管、人脸识别、密码开门、刷卡开门、扫码开门、呼梯",
            communication: " 以太网",//通讯方式
            size: " 8英寸",//屏幕尺寸
            resolution: " 1280*800",//分辨率
            material: " 铝合金面板",//产品材质
            workVoltage: " 9-15VDC",//工作电压
            Installation: " 嵌入式/壁挂",//安装方式
            camera: " 彩色：1928*1088 红外：1616*1232",//摄像头
            ProductSize: " 宽153mm*高290mm*深34mm",//产品尺寸
            installSize: " 宽133mm*高274mm*深24mm",//安装尺寸
            pic: p8,
            pic2: b8
        },
        //智慧梯形
        {
            id: "FacialLadder",
            name: "人脸梯控机",
            function: "人脸呼梯、刷卡呼梯、报警求助、梯联网",
            communication: " 以太网",//通讯方式
            size: " 4.3英寸",//屏幕尺寸
            resolution: " 360*640",//分辨率
            storage: " 8GB eMMC",//存储方式
            workVoltage: " 12~24V DC",//工作电压
            pic: L1,
            pic2: b9
        },
        {
            id: "fiveExtension",
            name: " 五方对讲分机",
            function: " 人脸呼梯、刷卡呼梯、报警求助、梯联网",
            communication: " 4G（支持ESIM卡和micro SIM卡）",//通讯方式
            cpu: " ARM 1G",
            calls: " 4路",//通话器接口数量
            PowerMode: " DC12V",//供电方式
            consumption: " 4W",//功耗
            workEnvironment: " -10~55℃，93%RH ",//工作环境
            Installation: " 壁挂安装",//安装方式
            pic: L2,
            pic2: b10
        },
        {
            id: "computerRoom",
            name: "机房电话",
            function: "电梯对讲、紧急救援、困人求助、电梯维修",
            pic: L3,
            pic2: b10
        },
        {
            id: "analogCall",
            name: "模拟通话器",
            function: "电梯对讲、紧急救援、困人求助、电梯维修",
            pic: L4,
            pic2: b10
        },
        {
            id: "fivePAD",
            name: "五方对讲PAD",
            function: "电梯对讲、紧急救援、困人求助、语音录音呼叫、电梯监控",
            communication: " WiFi/4G",//通讯方式
            size: " 10英寸",//屏幕尺寸
            resolution: " 640*360",//分辨率
            material: " 金属外壳，玻璃面板",//产品材质
            PowerMode: " 内置锂电池",//供电方式
            Installation: " 便携式平板电脑",//安装方式
            pic: L5,
            pic2: b11
        },
        {
            id: "8inchSecurityLadder",
            name: "8寸保安分机",
            function: "可视对讲、监视开锁、电梯智控、小区服务",
            communication: " 以太网",//通讯方式
            size: " 8英寸",//屏幕尺寸
            resolution: " 800*1280",//分辨率
            storage: " FLASH EMMC KLM8G1WEPD-8031(8Gbyte)",//存储方式
            workVoltage: " 12V 电源输入(范围9V-15V)",//工作电压
            consumption: " 整机最大功耗 <8W",//功耗
            material: " 铝合金（手柄ABS）",//产品材质
            ProductSize: " 220mm*190mm*65mm",//产品尺寸
            pic: p6,
            pic2: b10
        },
    ]
    const { id } = useParams();
    const [renew, setRenew] = useState(id);
    //获取与id对应的对象
    const ProductDetailsItem = ProductDetails.find(obj => obj.id === id);
    //获取被选中的索引
    let targetIndex = ProductDetails.indexOf(ProductDetailsItem);
    //点击上下一个更新页面
    useEffect(() => {
        window.scrollTo(0, 0);
        setRenew(true)
    }, [id])

    return (
        <div style={{ background: "#F9F9F9", paddingBottom: "168px" }}>
            <img src={headerImg} alt="" style={{ height: "100%", width: "100%" }} />
            <div
                style={{
                    height: "88px",
                    width: "100%",
                    paddingLeft: "72px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "24px",
                    boxShadow: "none",
                    background: "#fff",
                    borderBottom: "1px solid #E6E6E6",
                    marginBottom: "40px",
                }}>
                <Link to={'/home'} style={{ color: "#020052", }}>首页</Link>/
                <Link to={'/productCenter/all'} style={{ color: "#020052", }}>产品中心</Link>/
                <a href="" style={{ fontSize: "22px", color: "#757575" }}>十寸智慧屏</a>
            </div>

            <div style={{
                margin: "0 auto ",
                background: "#fff",
                width: "1552px",
                border: "1px solid #E6E6E6",
            }}>
                <div style={{ fontSize: "36px", color: "#020052", letterSpacing: "4px", margin: "29px 0 0 52px" }}>{ProductDetailsItem.name}</div>
                <div style={{ width: "100%", textAlign: "center" }} >
                    <img src={ProductDetailsItem.pic} alt="" />
                </div>

                {/* 功能 */}
                <div style={{ margin: "59px 0 28px 69px", fontSize: "24px", letterSpacing: "6px" }}>主要功能</div>
                <hr style={{ margin: "0 61px", color: "#D9E6FF" }}></hr>
                <div style={{ margin: "20px 0 24px 204px", color: "#505050", fontSize: "22px" }}>{ProductDetailsItem.function}</div>

                {/* 产品详情 */}
                <div style={{ margin: "59px 0 28px 69px", fontSize: "24px", letterSpacing: "6px" }}>产品详情</div>
                <hr style={{ margin: "0 61px", color: "#D9E6FF" }}></hr>
                <div style={{ display: "flex", flexDirection: "column", margin: "20px 0 86px 204px", color: "#505050", fontSize: "22px" }}>
                    {ProductDetailsItem.hasOwnProperty('communication') && (<p>通讯方式 :{ProductDetailsItem.communication}</p>)}
                    {ProductDetailsItem.hasOwnProperty('size') && (<p>屏幕尺寸 :{ProductDetailsItem.size}</p>)}
                    {ProductDetailsItem.hasOwnProperty('resolution') && (<p>分辨率 :{ProductDetailsItem.resolution}</p>)}
                    {ProductDetailsItem.hasOwnProperty('storage') && (<p>存储 :{ProductDetailsItem.storage}</p>)}
                    {ProductDetailsItem.hasOwnProperty('workVoltage') && (<p>工作电压 :{ProductDetailsItem.workVoltage}</p>)}
                    {ProductDetailsItem.hasOwnProperty('consumption') && (<p>功耗 :{ProductDetailsItem.consumption}</p>)}
                    {ProductDetailsItem.hasOwnProperty('material') && (<p>产品材质 :{ProductDetailsItem.material}</p>)}
                    {ProductDetailsItem.hasOwnProperty('ProductSize') && (<p>产品尺寸 :{ProductDetailsItem.ProductSize}</p>)}
                    {ProductDetailsItem.ratedVoltage && (<p>额定电源 :{ProductDetailsItem.ratedVoltage}</p>)}
                    {ProductDetailsItem.hasOwnProperty('Installation') && (<p>安装方式 :{ProductDetailsItem.Installation}</p>)}
                    {ProductDetailsItem.hasOwnProperty('camera') && (<p>摄像头 :{ProductDetailsItem.camera}</p>)}
                    {ProductDetailsItem.installSize && (<p>安装尺寸 :{ProductDetailsItem.installSize}</p>)}
                    {ProductDetailsItem.cpu && (<p>cpu :{ProductDetailsItem.cpu}</p>)}
                    {ProductDetailsItem.calls && (<p>通话器接口数量 :{ProductDetailsItem.calls}</p>)}
                    {ProductDetailsItem.PowerMode && (<p>供电方式 :{ProductDetailsItem.PowerMode}</p>)}
                    {ProductDetailsItem.workEnvironment && (<p>工作环境 :{ProductDetailsItem.workEnvironment}</p>)}

                </div>

                <img src={ProductDetailsItem.pic2} alt="" style={{ margin: "0 61px" }} />
                <hr style={{ marginTop: "39px", color: "#D9E6FF" }} />

                {/* 上一个下一个按钮 */}
                <div style={{ width: "100%", height: "85px", fontSize: "22px", padding: "0 41px", letterSpacing: "5px" }}>
                    {targetIndex !== 0 && <Link style={{ color: "#757575", lineHeight: "85px" }} to={`/productCenter/${ProductDetails[targetIndex - 1].id}`}>
                        上一个 {ProductDetails[targetIndex - 1].name}
                    </Link>}
                    {targetIndex !== ProductDetails.length - 1 && <Link style={{ color: "#757575", lineHeight: "85px", float: "right" }} to={`/productCenter/${ProductDetails[targetIndex + 1].id}`}>
                        下一个 {ProductDetails[targetIndex + 1].name}</Link>}
                </div>
            </div>
        </div>
    )
}
export default ProductCenterChild