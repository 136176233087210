import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dot from '../../static/productCenter/dots.png'
import headerImg from '../../static/productCenter/prdouctChild.png'
import p1 from '../../static/productCenter/people/p1.png'
import p2 from '../../static/productCenter/people/p2.png'
import p3 from '../../static/productCenter/people/p3.png'
import p4 from '../../static/productCenter/people/p4.png'
import p6 from '../../static/productCenter/people/p6.png'
import p7 from '../../static/productCenter/people/p7.png'
import p8 from '../../static/productCenter/people/p8.png'

import L1 from '../../static/productCenter/ladder/L1.png'
import L2 from '../../static/productCenter/ladder/L2.png'
import L3 from '../../static/productCenter/ladder/L3.png'
import L4 from '../../static/productCenter/ladder/L4.png'
import L5 from '../../static/productCenter/ladder/L5.png'
import './index.css'
const ProductCenter = (props) => {
    let useLocationData = useLocation();
    const peopletRef = useRef(null);
    const ladderRef = useRef(null);
    const navigate = useNavigate()
    const product_people = [
        {
            img: p1,
            id: "10inchIntelligentDual",
            tiele: "10寸双屏智能中控",
            text: "访客呼叫、呼叫物管、远程开门、智能控制"
        },
        {
            img: p2,
            id: "10inchIntelligent",
            tiele: "10寸智能中控",
            text: "智能控制、场景控制、语音控制"
        },
        {
            img: p3,
            id: "7inchIntelligent",
            tiele: "7寸智能中控",
            text: "远程开门、家庭安防、场景控制、语音控制"
        },
        {
            img: p4,
            id: "4inchIntelligent",
            tiele: "4寸智能中控",
            text: "智能控制、场景控制、语音控制"
        },
        {
            img: p6,
            id: "8inchSecurity",
            tiele: "8寸保安机",
            text: "可视对讲、监视开锁、电梯智控、小区服务"
        },
        {
            img: p7,
            id: "10inchFacial",
            tiele: "10寸人脸识别门口机",
            text: "访客呼叫、呼叫物管"
        },
        {
            img: p8,
            id: "8inchFacial",
            tiele: "8寸人脸识别门口机",
            text: "访客呼叫、呼叫物管"
        },
    ]

    const product_ladder = [
        {
            img: L1,
            id: "FacialLadder",
            tiele: "人脸梯控机",
            text: "人脸呼梯、刷卡呼梯、报警求助、梯联网"
        },
        {
            img: L2,
            id: "fiveExtension",
            tiele: "五方对讲机",
            text: "人脸呼梯、刷卡呼梯、报警求助、梯联网"
        },
        {
            img: L3,
            id: "computerRoom ",
            tiele: "机房电话",
            text: "电梯对讲、紧急救援、困人求助、电梯维修"
        },
        {
            img: L4,
            id: "analogCall ",
            tiele: "模拟通话器",
            text: "电梯对讲、紧急救援、困人求助、电梯维修"
        },
        {
            img: L5,
            id: "fivePAD ",
            tiele: "五方对讲PAD",
            text: "电梯对讲、紧急救援、电梯监控、语音录音呼叫"
        },
        {
            img: p6,
            id: "8inchSecurityLadder",
            tiele: "8寸保安机",
            text: "可视对讲、监视开锁、电梯智控、小区服务"
        },
    ]



    //锚点
    useEffect(() => {
        const id = useLocationData.search.replace('?id=', '');
        if (id === "people") {
            window.scrollTo({
                top: peopletRef.current.offsetTop - 120,
                behavior: 'smooth',
            });
        } else if (id === "ladder") {
            window.scrollTo({
                top: ladderRef.current.offsetTop * 0.8,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo(0, 0)

        }
    }, [useLocationData]);

    return (
        <div className='ProductCenter'>
            <img src={headerImg} alt="" style={{ height: "auto", width: "100%", marginBottom: "64px" }} />
            {/* 智慧人行 */}
            <div className='productCenter-people' ref={peopletRef} id='people'>
                <div className='productCenter-title'>
                    <span style={{ fontSize: "36px", height: "54px", color: "#757575", fontWeight: "400" }}>智慧人行</span>
                    <span style={{ position: "absolute", bottom: 0, right: 0 }}><img src={dot} alt=""></img></span>
                </div>

                <div className='productCenter-content'>
                    {product_people.map((item, index) => {
                        return <div className='productCenter-content-item' key={index}>
                            <div className='productCenter-imgDiv' onClick={() => { navigate(`/productCenter/${item.id}`) }} >
                                <img src={item.img} alt="" />
                            </div>

                            <div className='productCenter-content-item-title' onClick={() => { navigate(`/productCenter/${item.id}`) }}>{item.tiele}</div>

                            <div style={{ fontSize: "22px", color: "#A5A5A5" }}>{item.text}</div>
                        </div>
                    })}
                </div>
            </div>


            {/* 智慧梯行 */}
            <div className='productCenter-ladder' ref={ladderRef} id='ladder'>
                <div className='productCenter-title' >
                    <span style={{ fontSize: "36px", height: "54px", color: "#757575", fontWeight: "400" }}>智慧梯行</span>
                    <span style={{ position: "absolute", bottom: 0, right: 0 }}><img src={dot} alt=""></img></span>
                </div>

                <div className='productCenter-content'>
                    {product_ladder.map((item, index) => {
                        return <div className='productCenter-content-item' key={index}>
                            <div className='productCenter-imgDiv' onClick={() => { navigate(`/productCenter/${item.id}`) }}>
                                <img src={item.img} alt="" />
                            </div>
                            <div className='productCenter-content-item-title' onClick={() => { navigate(`/productCenter/${item.id}`) }}>{item.tiele}</div>
                            <span style={{ fontSize: "22px", color: "#A5A5A5" }}>{item.text}</span>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}
export default ProductCenter