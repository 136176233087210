import React, { useEffect } from 'react'
import './index.css'
import s1 from '../../static/solution/s1.png'
import s2 from '../../static/solution/s2.png'
import s3 from '../../static/solution/s3.png'
const Solution = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ background: "#F5F5F5" }} >

            <div className='solution-box'>

                <div className='solution-card' style={{ marginTop: "45px" }}>

                    <div style={{ textAlign: "center" }}>
                        <p className='title' style={{ width: "500px" }}>腾讯连连精选智慧社区</p>
                    </div>
                    <p className='text' >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;腾讯连连精选智慧社区应用于智慧社区到智慧家庭，实现人居空间环境的智能化、网络化、信息化。基于腾讯TRTC技术，实现一键呼梯、一键开门、与访客实时可视对讲等功能，让用户无感通行、出门无忧。腾讯连连精选+智慧社区SaaS平台，为社区管理人员提供全方位平台服务，包括人行、梯行、停车、社区养老、智能家居等系统管理，实现信息共享和互动,助力用户之间以及社区用户与社区管理者之间建立更加紧密的沟通和服务；让用户享受到智慧社区服务带来的安全、便捷、智慧化的宜居环境体验。
                    </p>
                    <img src={s1} alt="" />
                </div>

                <div className='solution-card solution-cardNew' style={{ marginTop: "45px" }}>
                    <img src={s2} alt="" />
                    <div className=''>
                        <div style={{ textAlign: "center" }}>
                            <p style={{ width: "150px" }} className='title' >云人行</p>
                        </div>
                        <p className='text' >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;云人行门禁对讲主要用于住宅社区,方案基于ESA SaaS平台，由人脸识别门禁、ESA中控、ESA业主小程序等组成。
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ESA 云人行解决方案可局域网组网，也可完全基于互联网架构，实现人脸识别开门、梯控、呼叫对讲、呼叫到业主小程序等功能。
                        </p>
                    </div>

                </div>

                <div className='solution-card solution-cardNew' style={{ marginTop: "45px" }}>
                    <img src={s3} alt="" />
                    <div style={{ marginLeft: "82px" }}>

                        <div style={{ width: "100%", textAlign: "center" }}>
                            <p style={{ width: "300px" }} className='title' >电梯五方通话</p>
                        </div>
                        <p className='text' >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;物联网电梯五方通话系统是指管理中心(值班室)、电梯机房、电梯轿厢、轿厢顶部、电梯井道底部这五方之间进行的通话。通过有线方式，连接除管理中心外的四方通话设备，并通过4G无线方式与管理中心建立通话连接。
                        </p>
                    </div>

                </div>

            </div>

        </div>
    )
}
export default Solution