import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import about from '../../static/aboutus/about.png'
import './index.css'
import A1 from '../../static/aboutus/Company1.png'
import A2 from '../../static/aboutus/Company2.png'
import A3 from '../../static/aboutus/Company3.png'
import A4 from '../../static/aboutus/Company4.png'

const CompanyProfile = () => {
    return (
        <div className='about-box'>
            <div className='about-article'>
                <img src={A1} alt="" className='top-img-margin' />
                <div className='about-title'>
                    <p>&nbsp;&nbsp;公司简介</p>
                </div>

                <div className='about-text'>

                    <p> &nbsp;&nbsp;&nbsp;&nbsp;珠海数字动力科技股份有限公司，成立于2011年，位于美丽的海滨城市珠海市，是一家致力于社区安防技术的研发，为智慧社区提供数字化楼宇对讲解决方案、社区安全产品和智能家居产品服务的“高新技术企业”。公司秉承着着“创新、务实、高效”的经营理念，不断推出创新技术和产品，致力为客户提供更优质的产品和服务。</p>
                    <br />
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;经过多年的积累，公司凝聚了一大批具有资深行业背景的软硬件技术开发人员，拥有芯片级开发设计能力，在嵌入式芯片和操作系统、嵌入式软件、音视频编解码传输、手机开发、云计算和大数据分析等领域拥有核心技术。公司精心打造了一系列包含了云服务、智能安防、人脸识别、智能监控、电梯五方通话、全屋智能、语音识别等众多功能的产品和服务管理平台，致力于提供全方位的智能解决方案；从而让用户享受到更便捷、更高效、更智能的生活体验。</p>
                    <br />
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;未来，珠海数字动力科技股份有限公司将继续秉承“创新、务实、高效”的经营理念，不断推出创新技术和产品，为客户提供更优质的产品和服务。同时，公司也将积极拓展国内外市场，与更多的合作伙伴建立战略合作关系，共创数字化时代的新篇章。 </p>

                </div>

                <img src={A2} alt="" style={{ marginBottom: "100px" }} />
            </div>


        </div>
    );
};

const Culture = () => {
    return (
        <div className='about-box'>
            <div className='about-article' style={{ paddingBottom: "130px" }}>
                <img src={A3} alt="" className='top-img-margin' />
                <div className='about-title'>
                    <p>&nbsp;&nbsp;企业文化</p>
                </div>

                <div className='about-text'>

                    <p> &nbsp;&nbsp;&nbsp;&nbsp;（1）企业使命：公司致力于为客户提供优质、高效的数字化家庭解决方案和服务，让人们的生活更加智能化和便捷。</p>
                    <br />
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;（2）企业意愿：成为全球数字化家庭解决方案的领军企业，通过不断创新和卓越的服务，为人们创造更加智能、便捷、美好的未来。</p>
                    <br />
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;（3）企业价值观：以客户为中心，以创新驱动发展，追求卓越品质，注重团队合作，坚持诚信经营。</p>
                    <br />
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;（4）企业精神：勇于创新、持续奋斗、协作共赢、追求卓越。 </p>
                    <br />
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;（5）经营理念：以市场为导向，以客户为中心，注重产品创新、服务提升、资源整合和合作共赢。</p>
                    <br />
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;（6）品牌理念：通过创新、专业和高质量的产品与服务，赢得客户的信任和支持，为客户提供卓越的数字化产品和服务。</p>
                    <br />
                    <p style={{ lineHeight: "50px" }}> &nbsp;&nbsp;&nbsp;&nbsp;（7）发展理念：坚持“创新、服务、合作、责任”的发展理念，注重科技创新和研发投入，致力于提供创新的数字化解决方案，满足客户的多样化需求；同时积极履行企业社会责任，关注绿色环保和可持续发展，与合作伙伴共同推动智能化生活的进步和发展。</p>
                </div>
            </div>
        </div>
    )
}

const Contact = () => {
    return (
        <div className='about-box' style={{ paddingBottom: "500px" }}>
            <div className='about-article'>
                <img src={A4} alt="" className='top-img-margin' />

                <div className='about-title'>
                    <p>&nbsp;&nbsp;联系方式</p>
                </div>

                <div className='about-text'>

                    <p> &nbsp;&nbsp;&nbsp;&nbsp;珠海数字动力科技股份有限公司</p>
                    <br />
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;地址：珠海市香洲区大学路101号清华科技园4栋401-405</p>
                    <br />
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;电话： 0756-3613268</p>

                </div>
            </div>

        </div>
    )
}


const Aboutus = () => {
    let { search } = useLocation();

    const [show, setShow] = useState(() => {
        return search.replace("?id=", "")
    })

    useEffect(() => {
        let name = search.replace("?id=", "")
        setShow(name)
        window.scrollTo(0, 0)


    }, [search])

    return (
        <div>
            <img src={about} alt="" style={{ width: "100%", height: "auto" }} />

            <div className='about-crumb'>
                < Link style={{ color: "#020052", }} to={'/home'}>首页/</Link>

                <a style={{ color: "#020052", }}
                    onClick={() => {
                        setShow('CompanyProfile')
                    }}
                >关于我们/</a>
                <a style={{ fontSize: "22px", color: "#757575" }}>
                    {show === "CompanyProfile" ? "公司简介" : show === "Culture" ? "企业文化" : show === "Contact" ? "联系我们" : ""}
                </a>
            </div>

            {show === "CompanyProfile" && <CompanyProfile />}
            {show === "Culture" && <Culture />}
            {show === "Contact" && <Contact />}
        </div>
    )
}
export default Aboutus